<template lang="pug">
    section#comunicacao
        .content
            Editor(:contents="editor")           
            Link(:contents="contents.content").link {{contents.content.name}}
        ul.noticias
            li.noticia(v-for="noticia, index in news" v-if="index < 3 && news.length > 0")
                router-link(:to="{name: 'Noticia', params: {slug: noticia.translations[translationIndex].slug, locale: $store.state.locale}}")
                    .img
                        img(:src="noticia.translations[translationIndex].thumb") 
                    .container
                        h3 {{ noticia.translations[translationIndex].title }}
                        span.info
                            strong Postado por 
                            | Coamo | {{ formatDate(noticia.translations[translationIndex].publishedAt) }}
                        p {{ noticia.translations[translationIndex].description }}
                        span.mais Ler mais 
                            FontAwesomeIcon(:icon="icons.faChevronRight")
</template>

<script>
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Editor from '@sections/Pagina/Editor/Editor'
import Link from '@components/Link/Link'

export default {
    name: "section-comunicacao",
    props: {
		contents: {
			type: Object,
		}
	},
    components: {
        Editor,
        Link,
    },
    data(){
        return {
            icons: {
                faChevronRight
            },
            translationIndex: 0,
            lista: '',
        }
    },
    methods: {
        formatDate(date) {
			const objData = new Date(date)
            const mes = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"]
			let formatted = `${objData.getDate()} de ${mes[objData.getMonth()]}, ${objData.getFullYear()}`
			return formatted
		},
    },
    computed: {
        editor() {
            return {content: this.contents.content.editor}
        },
		news() {
			return this.$store.state.news.map(news => ({
				...news,
				translations: news.translations.map(translation => ({
					title: translation.title,
					thumb: translation.thumb ? `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${translation.thumb.id}` : ``,
					description: translation.description,
					slug: translation.slug,
					publishedAt: translation.publishedAt,
				}))	
			}))
		}
	},
	created() {
		this.$store.dispatch('fetchNews', { skip: 0, take: 5 })
	}
}
</script>

<style lang="stylus" scoped src="./NewsGallerySection.styl"></style>