import { render, staticRenderFns } from "./NewsGallerySection.vue?vue&type=template&id=319e74e4&scoped=true&lang=pug&"
import script from "./NewsGallerySection.vue?vue&type=script&lang=js&"
export * from "./NewsGallerySection.vue?vue&type=script&lang=js&"
import style0 from "./NewsGallerySection.styl?vue&type=style&index=0&id=319e74e4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319e74e4",
  null
  
)

export default component.exports